.movable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.color-primary {
  color: $primary;
}

.color-danger {
  color: $danger;
}

.color-black {
  color: $black;
}

.t-menu,
.t-menu1 {
  display: flex;
  flex-direction: column;

  &__header {
    min-height: 40px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-top: 1px solid $grey-70;
    color: $grey-90;
    letter-spacing: 1px;

    &.no-border {
      border-top: none !important;
    }

    &.t-dense {
      min-height: 25px !important;
      padding-top: 12px;
    }

    .header__header__title  {
      font-size: 11px;
    }
  }

  &__content {
    height: calc(100% - 40px);
    overflow: auto;
    &.over-flow {
      overflow: unset;
    }

    .t-label {
      font-size: 13px;
      letter-spacing: 0;
    }
    .t-value {
      font-size: 18px;
    }

    > .tree-folder {

      .folder__arrow {
        svg {
          margin-top: 10px !important;
          color: $grey-90;
        }
      }

      .folder__icon {
        svg {
          margin-top: 8px !important;
        }
      }

      .folder__name {
        padding-top: 0px;
      }

      .folder__name {
        &.item-name {
          padding: 0 !important;
        }
      }

      .folder__icon {
        &.item-icon {
          margin-top: -5px !important;
        }
      }

      > .sublists {
        > .tree-list-item {
          &:hover {
            background-color: $light-blue3;
          }
          > .folder {
            .folder__icon {
              padding-left: 5px !important;
              top: -2px;
              position: relative;
            }
          }
        }
      }

      > .animated {
        > .sublists {
          > .tree-list-item {
            &:hover {
              background-color: $light-blue3;
            }
            > .folder {
              .folder__icon {
                padding-left: 5px !important;
                top: -2px;
                position: relative;
              }
            }
          }
        }
        > .subfolders {
          > .tree-folder {
            > .animated {
              > .sublists {
                > .tree-list-item {
                  &:hover {
                    background-color: $light-blue3;
                  }
                  > .folder {
                    > .folder__icon {
                      padding-left: 5px !important;
                      top: -2px;
                      position: relative;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .icon {
    margin-top: -5px;
  }

  .item {
    color: $dark;
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: background-color 100ms ease-in-out;
    white-space: nowrap;

    &__no-cursor {
      cursor: context-menu !important;
    }
  }

  .item:hover {
    background: $grey-40;
    color: $dark;
    text-decoration: none;
  }

  .item.router-link-exact-active {
    background: $grey-40;
  }

}

.t-scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}
.t-scroll-y1 {
  height: calc(100vh - 478px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.t-scroll-y2 {
  height: calc(100vh - 478px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.no-border {
  border: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid $grey-60 !important;
  border-radius: 0;
}

.border-top {
  border-top: 1px solid $grey-60 !important;
  border-radius: 0;
}

.border-left {
  border-left: 1px solid $grey-60 !important;
  border-radius: 0;
}

.border-right {
  border-right: 1px solid $grey-60 !important;
  border-radius: 0;
}

/*.q-tree {

  .q-tree__node {
    padding-left: 0;

    .q-icon {
      padding: 0px 2px 4px 0px;
      font-size: 12px;
      margin-left: 5px;
    }

    > .q-tree__node-header {
      padding-left: 0;

      &:hover, &.active {
        background-color: lighten($green-10, 8%);
        border-radius: 0;
      }

      > .q-focus-helper {
        width: 100% !important;
      }

      > .q-tree__node-header-content {
        width: 100% !important;
        padding: 0;
        > .row {
          width: 100% !important;
        }
        .tree-list-item {
          width: 100%;
          color: black;
          text-decoration: none;
        }
      }
    }

    > .q-tree__node-collapsible {
      padding-left: 0;
      > .q-tree__children {
        > .q-tree__node {
          > .q-tree__node-header {
            padding-left: 15px;
          }
          > .q-tree__node-collapsible {
            > .q-tree__children {
              > .q-tree__node {
                > .q-tree__node-header {
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
    }

  }

  button {
    background-color: rgba(0,0,0,0);
    border: 0;
    top: -5px;
  }
}

.q-tree__node {
  // padding: 0 !important;
  padding-left: 0;

  .q-tree__children {
    padding-left: 0;
  }

  .q-tree__node-header-content {
    padding-left: 0 !important;
  }
}*/

.t-directory {
  padding: 0 !important;
}

.t-flex-group,
.t-flex-group__no-bg {
  display: flex;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.t-flex-group {
  background-color: $white;
}
.t-flex-group__no-bg {
  background-color: $grey-1;
  overflow: hidden;
  .card {
    flex: 0 0 auto;
  }
  .t-panel-container {
    flex: 1 1 auto;
    .q-panel {
      overflow: hidden;
    }
  }
}

.t-panel-container {
  height: 100%;
  overflow: hidden;

  .q-tab-panels {
    height: 100%;
    overflow: hidden;
  }
}

.t-cascade-card {
  width: 100%;
  width: 426px;
  &__mini {
    width: 150px;
  }
}

.breadcrumbs {
  &__directory {
    padding-right: 5px;
    div {
      font-family: Roboto;
      font-size: 12px;
    }
  }
  &__name {
    top: 2px;
    position: relative;
  }
}

.right-spacing-1 {
  margin-right: 10px;
}
.right-spacing-2 {
  margin-right: 15px;
}

.text-10 {
  font-size: 10px;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.bg-btn-red {
  background-color: $light-red2 !important;
}

.scrollable-dropdown-list {
  .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
  }
}

.filter-chips {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
}

.hidden {
  display: none;
}

iframe > html {
  overflow: hidden;
}

.session-settings-title {
  max-width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

#session-list-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.session-sidebar {
  min-width: 300px !important;
}

.hubspot-iframe-container,
.referrizer-iframe-container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  #hubspot-crm,
  #referrizer-crm {
    width: 100%;
    height: 100%;
  }
}

.session-settings-sidebar {
  .q-item__section {
    max-width: 135px;

    button {
      max-height: 32px;
    }
  }
}
