.svg-fill-green {
  fill: $green;
}

.svg-fill-dark {
  fill: $dark;
}

.svg-fill-black {
  fill: $black;
}

.svg-fill-grey-dark {
  fill: $grey-dark;
}

.svg-fill-grey-2 {
  fill: $grey-2;
}

.svg-fill-grey-light {
  fill: $grey-light;
}

.svg-fill-grey-light2 {
  fill: $grey-light2;
}

.svg-fill-grey-light3 {
  fill: $grey-light3;
}

.svg-fill-grey-light4 {
  fill: $grey-light4;
}

.svg-fill-grey-light10 {
  fill: $grey-light10;
}

.svg-fill-grey-mid {
  fill: $grey-mid;
}

.svg-fill-white {
  fill: $white;
}

.svg-fill-blue {
  fill: $blue;
}

.svg-fill-orange {
  fill: $orange;
}

.svg-fill-red {
  fill: $red;
}

.svg-fill-light-green {
  fill: $light-green;
}

.svg-fill-flesh {
  fill: $flesh;
}

.svg-fill-light-red {
  fill: $light-red;
}

.svg-fill-light-blue {
  fill: $light-blue;
}

.svg-fill-light-orange {
  fill: $light-orange;
}

.svg-fill-grey-30 {
  fill: $grey-30;
}

.svg-fill-grey-90 {
  fill: $grey-90;
}

.svg-fill-primary {
  fill: $primary;
}
