.vertical-collapse-enter-active, 
.vertical-collapse-leave-active {
  transition: all .3s;
  max-height: 1500px;
}

.vertical-collapse-enter,
.vertical-collapse-leave-to {
  max-height: 0px;
}

.list-fade-enter,
.list-fade-leave-to {
  opacity: 0;
}

.list-fade-leave-active {
  position: absolute;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-leave-active {
  transform: translateX(100%);
}

.horizontal-slide-left-enter-active, 
.horizontal-slide-left-leave-active,
.horizontal-slide-right-enter-active, 
.horizontal-slide-right-leave-active {
  transition: transform 0.5s;
}

.horizontal-slide-left-enter,
.horizontal-slide-left-leave {
  transform: translateX(-100vw);
}

.horizontal-slide-right-enter,
.horizontal-slide-right-leave  {
  transform: translateX(100vw);
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}
