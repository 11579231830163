@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,700;1,300;1,700&display=swap');

@font-face {
  font-family: 'Aloicons';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./fonts/aloicons.woff2') format('woff2');
}
